import {FaFacebookF, FaTwitter, FaYoutube} from 'react-icons/fa'

export default function Footer(){
    return(
        <>
<footer className="page-footer font-small blue pt-4 text-light" style={{backgroundColor:'#215273'}}>
  <div className="container-fluid text-md-left">
    <div className="row">
      <div className="col-md-6 mt-md-0 mt-3 mb-4 offset-1">
        <img src="./images/logo-white.png" style={{maxWidth: '25%'}}/>
      </div>

      <div className="col-md-3 mb-md-0 mb-3 text-light offset-1">
        <h5 className="">Redes Sociales</h5>

        <div className="row col-6">
            <a href="#!" className="col text-light"><FaFacebookF/></a>
            <a href="#!" className="col text-light"><FaTwitter/></a>
            <a href="#!" className="col text-light"><FaYoutube/></a>
        </div>
      </div>

    </div>

  </div>

  <div className="footer-copyright text-center py-3">© 2022 Copyright
  </div>

</footer>
        </>
    )
}
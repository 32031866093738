import { useState,useEffect } from "react"
import {Container, Nav, Navbar} from 'react-bootstrap'
import logo from '../logo.svg';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}

export default function Navegacion(){

    let listener = null
    const [scrollState, setScrollState] = useState("")
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        
        listener = document.addEventListener("scroll", e => {
        var scrolled = document.scrollingElement.scrollTop
        if(windowDimensions.width > 480){
            if (scrolled >= 80) {
                setScrollState("#fff")
            } else {
                setScrollState("")
            }
        }else{
            setScrollState("#fff")
        }
        })
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        
        return () => {
        document.removeEventListener("scroll", listener)
        window.removeEventListener('resize', handleResize);
        }
    }, [scrollState])

    /*
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{backgroundColor: '#fff'}}>
          <Nav classNameName="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link>
          </Nav>
        </Navbar.Collapse>
    */


    return(
    <Navbar expand="lg" fixed='top' style={{backgroundColor: scrollState, maxWidth:'100%'}}>
      <Container>
        <Navbar.Brand href="#home" className='offset-lg-1'><img src='./svg.svg' style={{maxWidth: '18vh'}}/></Navbar.Brand>
        
      </Container>
    </Navbar>
    )
}
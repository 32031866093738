import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


export default function Infografia(){
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
    function handleResize() {
        setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    }, []);

    return(
        <>
            <div className="">
                {windowDimensions.width> 480 ? <img classname='img-fluid' style={{maxWidth:'100%'}} src='./images/pcbanner.png'/> :
                <img classname='img-fluid' style={{maxWidth:'100%'}} src='./images/phonebanner.png'/>}  
            </div>
        </>
    )
}

export default function Banner(){
    
    return(
        <>
        <div className="d-flex align-items-center justify-content-center text-center text-light" style={{minHeight:'80vh', backgroundImage: 'url("./images/bannerwheat.png")', width:'100%', backgroundRepeat: 'no-repeat', backgroundSize:'cover'}}>
            <div>
            <h1 className='align-middle'><strong>Tierra Inteligente</strong></h1>
            <p className='align-middle'>Timeless, effortless and limitless.</p>
            <p className="lead">
                <a className="btn btn-success btn-lg" style={{backgroundColor:'#7de397', borderColor:'#7de397'}} href="#" role="button">See more</a>
            </p>
            </div>
            
            </div>
        </>
    )
}
import './App.css';
import Banner from './components/Banner';
import Cards from './components/Cards';
import Formulario from './components/Formulario';
import Footer from './components/Footer';
import Infografia from './components/Infografia';
import Navegacion from './components/Navegacion';

function App() {

  return (
    <>
    <Navegacion/>
    <Banner/>
    <Infografia/>
    <Cards/>
    <Formulario/>
    <Footer/>
    
    </>
  );
}

export default App;

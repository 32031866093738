import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, CardGroup, CardColumns, Col, Row, Container } from "react-bootstrap";
import { FaDesktop } from 'react-icons/fa'
import {RiRoadMapLine} from 'react-icons/ri'
import {MdSatellite} from 'react-icons/md'


export default function Cards(){
    return(
        <>
      <section style={{background:'#385776'}}>
        <br></br>
      <div className="container" style={{color:'white',textAlign:"center"}}>
                <h3>Descubre lo que podemos<br/> hacer por ti</h3>
      </div>
      
      <Container className='pt-5'>
        <Row className='row-cols-1 row-cols-md-3 g-4"'>
        <Col>
      <a href='https://www.agleader.com/' style={{ textDecoration:"none", color:'black'}}>
      <Card className='mb-5' style={{borderRadius:'5px', height: '25rem', width:'100%', boxShadow:'10px 10px 15px rgba(0,0,0,0.3)'}}>
        <Card.ImgOverlay style={{margin:'-2em auto -2em', width:'80%',height: '10rem', background:'#98FB98', borderRadius:'5px', textAlign:'center'}} ><FaDesktop color="white" style={{marginTop:'7%', fontSize:'100px', color:'white'}}/></Card.ImgOverlay>
        <Card.Body style={{marginTop:'100px'}}>
          <br></br><br></br>
          <Card.Title  style={{textAlign:"center", color:'#385776'}}>Automatización de maquinaria</Card.Title><br/>
          <Card.Text  style={{textAlign:"center"}}>
              Conecte cada equipo en su granja durante cada temporada con InCommand y AgFiniti.
          </Card.Text>
        </Card.Body>
      </Card>
      </a>
      </Col>
      <Col>
      <a href='https://www.soiloptix.com' style={{ textDecoration:"none", color:'black'}}>
      <Card className="mb-5" style={{borderRadius:'5px', height: '25rem', width:'100%', boxShadow:'10px 10px 15px rgba(0,0,0,0.3)'}}>
      <Card.ImgOverlay style={{margin:'-2em auto -2em', width:'80%',height: '10rem', background:'#98FB98', borderRadius:'5px', textAlign:'center'}} ><RiRoadMapLine color="white" style={{marginTop:'7%', fontSize:'100px', color:'white'}}/></Card.ImgOverlay>
        <Card.Body style={{marginTop:'150px'}}>
          <Card.Title  style={{textAlign:"center", color:'#385776'}}>Mapeo de suelo en alta resolución</Card.Title><br/>
          <Card.Text  style={{textAlign:"center"}}>
            Con la tecnología y el software de mapeo de suelos SoilOptix, los productores pueden descubrir focos de oportunidades en sus campos.
          </Card.Text>
        </Card.Body>
      </Card>
      </a>
      </Col>
      <Col>
      <a href='https://doc-dev.eos.com/api/#authorization-api' style={{ textDecoration:"none", color:'black'}}>
      <Card className="mb-5" style={{borderRadius:'5px', height: '25rem', width:'100%',  boxShadow:'10px 10px 15px rgba(0,0,0,0.3)'}}>
      <Card.ImgOverlay style={{margin:'-2em auto -2em', width:'80%',height: '10rem', background:'#98FB98', borderRadius:'5px', textAlign:'center'}} ><MdSatellite color="white" style={{marginTop:'7%', fontSize:'100px', color:'white'}}/></Card.ImgOverlay>
        <Card.Body style={{marginTop:'150px'}}>
          <Card.Title  style={{textAlign:"center", color:'#385776'}}>Imágenes satelitales</Card.Title><br/>
          <Card.Text style={{textAlign:"center"}}>
          Las imágenes satelitales le permiten dar un seguimiento a los índices vegetativos de sus cultivos.
          </Card.Text>
        </Card.Body>
      </Card>
      </a>
      </Col>
      </Row>
      <br></br><br></br>
      </Container>
      </section>
        </>
    )
}
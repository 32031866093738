import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { div, Container, Form, Row, Col,Button,Card,Url} from "react-bootstrap";
import {FaWhatsapp, FaRegEnvelope} from 'react-icons/fa'




class Formulario extends React.Component {
  
    render() {
    return(
        <>
        <section style={{ backgroundImage:'url("./imagecampo.png")', backgroundRepeat:'no-repeat', backgroundSize:'cover'}}>
            <Container className='pt-2'>
                <Row className='row-cols-1 row-cols-md-2 g-1' style={{ marginTop:'5em'}}>
                    <Col className="mb-5">
                        <div  style={{}}>
                            <div style={{}}>
                            <h1 className='align-middle' style={{color:'white', fontSize:'30px'}}>Comunícate con nosostros</h1><br></br>
                            <p className='align-middle' style={{color:'white',display:'none'}}>Siemp<br></br>eiusmod"</p>
                            </div>
                            <div className='pt-3' style={{marginTop:'2em'}}>
                                <h1 className='align-middle' style={{color:'white', fontSize:'17px'}}><FaWhatsapp/> Llámanos:</h1>
                                <p  className='align-middle text-light' style={{color:'white',fontSize:'15px'}}><a href="tel:+524696030709" style={{color:'white'}}>+52 469 603 0709</a></p>
                                <h1 className='align-middle' style={{color:'white', fontSize:'17px'}}><FaRegEnvelope/> Mándanos un correo:</h1>
                                <p  className='align-middle' style={{color:'white',fontSize:'15px'}}> <a href="mailto:ventas@tecnoagricolas.com" style={{color:'white'}}>ventas@tecnoagricolas.com</a></p>
                            </div>
                        </div>
                    </Col>
                    <Col className='md-5'>
                        
                    </Col>
                </Row>
            </Container>
            <div className='pt-5'>
                

            </div>
        </section>
        
        </>
    )
}
}

export default Formulario;